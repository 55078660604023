import React from 'react'
import InputSearch from '../../components/InputSearch';

const SearchUser = () => {
  return (
    <InputSearch />
  );
};

export default SearchUser;
